<template>
  <div :class="`section-title ${color} ${lineColor}`">
    <div class="title">
      <div slot="primary" class="title-text" >
        <slot name="primary"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sectionTitle',
  props: {
    color: {
      type: String,
      default: 'black' // white
    },
    lineColor: {
      type: String,
      default: 'EBEEF5' // br666
    }
  }
}
</script>

<style scoped lang="less">
@import "~@/styles/mixin";
.section-title{
  box-sizing: border-box;
  max-width: 1200px;
  padding: 40px 0 40px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  &.EBEEF5{
    border-bottom: 1px solid #EBEEF5;
  }
  &.br666{
    border-bottom: 1px solid #666;
  }
  &.white .title-text{
    color: #fff;
  }
  &.black .title-text{
    color: #002948;
  }
  .title-text{
    font-size: 40px;
    line-height: 1;
    color: #002948;
    font-weight: 400;
  }
  &:before{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%) translateY(25%);
    background-color: #FF5717;
    width: 100px;
    height: 2px;
  }
}
</style>
